// assets

import {
  IconBusinessplan,
  IconAward,
  IconCategory,
  IconCalendar,
  IconHome,
  IconWindmill,
} from '@tabler/icons';

// constants
const icons = {
  IconHome,
  IconBusinessplan,
  IconAward,
  IconCategory,
  IconCalendar,
  IconWindmill,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const app = {
  id: 'app',
  title: 'App',
  caption: '',
  type: 'group',
  children: [
    {
      id: 'app',
      title: 'Dashboard',
      type: 'item',
      url: '/app',
      icon: icons.IconHome,
      breadcrumbs: false,
    },
    // {
    //   id: 'calendar',
    //   title: 'Calendar',
    //   type: 'item',
    //   url: '/app/calendar',
    //   icon: icons.IconCalendar,
    //   breadcrumbs: false,
    // },

    {
      id: 'create-lesson',
      title: 'Create Lesson',
      type: 'collapse',
      icon: icons.IconWindmill,
      children: [
        // {
        //   id: 'single-lesson',
        //   title: 'Single Lesson',
        //   type: 'item',
        //   url: '/app/single-lesson',
        //   breadcrumbs: false,
        // },
        {
          id: 'package-lesson',
          title: 'Package Lesson',
          type: 'item',
          url: '/app/package-lesson',
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export default app;
