import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';

// achievement option routing

const AchievementList = Loadable(
  lazy(() => import('views/admin/achievement/AchievementList'))
);

// ==============================|| ACHIEVEMENT ROUTING ||============================== //

const AchievementRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/admin/achievement/achievement-list',
      element: <AchievementList />,
    },
  ],
};

export default AchievementRoutes;
