import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import { AdminRoutes, AppRoutes } from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useMemo } from 'react';
import { getAuthUser } from 'store/auth/helpers';

// ==============================|| APP ||============================== //

const App = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);

  const authUser = getAuthUser();

  const customization = useSelector((state) => state.customization);

  const Routes = useMemo(() => {
    return isLoggedIn && authUser?.user?.role === 'ADMIN' ? (
      <AdminRoutes />
    ) : (
      <AppRoutes />
    );
  }, [authUser?.user?.role]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {Routes}
          </LocalizationProvider>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
