import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';

// Calendar option routing
const Calendar = Loadable(lazy(() => import('views/app/calendar/Calendar')));

const CalendarAddLesson = Loadable(
  lazy(() =>
    import('views/app/calendar/components/CalendarAddLesson/CalendarAddLesson')
  )
);

// ==============================|| CALENDAR ROUTING ||============================== //

const CalendarRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/app/calendar',
      element: <Calendar />,
    },

    {
      path: '/app/calendar/add-lesson',
      element: <CalendarAddLesson />,
    },
  ],
};

export default CalendarRoutes;
