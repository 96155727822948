import admin from './admin';
import app from './app';
// ==============================|| MENU ITEMS ||============================== //

export const appItems = {
  items: [app],
};

export const adminItems = {
  items: [admin],
};
