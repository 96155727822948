// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { adminItems, appItems } from 'menu-items';
import { getAuthUser } from 'store/auth/helpers';
import { useMemo } from 'react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const authUser = getAuthUser();

  const menuItems = useMemo(() => {
    return authUser?.user?.role === 'ADMIN' ? adminItems : appItems;
  }, [authUser]);

  const navItems = menuItems.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
