import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';

// classroom option routing

const ClassroomListPage = Loadable(
  lazy(() => import('views/admin/classroom/ClassroomList.page'))
);

// ==============================|| CLASSROOM ROUTING ||============================== //

const ClassroomRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/admin/classroom',
      element: <ClassroomListPage />,
    },
  ],
};

export default ClassroomRoutes;
