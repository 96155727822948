import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import UserRoutes from './admin/UserRoutes';
import ClassroomRoutes from './admin/ClassroomRoutes';
import BusinessRoutes from './admin/BusinessRoutes';
import AmenityRoutes from './admin/AmenityRoutes';
import CategoryRoutes from './admin/CategoryRoutes';
import BranchRoutes from './admin/BranchRoutes';
import AchievementRoutes from './admin/AchievementRoutes';
import BusinessOwnerRoutes from './admin/BusinessOwnerRoutes';
import CalendarRoutes from './app/CalendarRoutes';
import PackageLessonRoutes from './app/PackageLessonRoutes';
import SingleLessonRoutes from './app/SingleLessonRoutes';
import AdminDashboardRoutes from './admin/AdminDashboardRoutes';
import AppDashboardRoutes from './app/AppDashboardRoutes';
import ErrorRoutes from './ErrorRoutes';
import ProfileRoutes from './ProfileRoutes';
import MyContentRoutes from './app/MyContentRoutes';

// ==============================|| ROUTING RENDER ||============================== //
const adminRoutes = [
  AdminDashboardRoutes,
  AuthenticationRoutes,
  BusinessOwnerRoutes,
  UserRoutes,
  ClassroomRoutes,
  AmenityRoutes,
  BranchRoutes,
  AchievementRoutes,
  BusinessRoutes,
  CategoryRoutes,
  ProfileRoutes,
  ErrorRoutes,
];

const appRoutes = [
  AppDashboardRoutes,
  MainRoutes,
  AuthenticationRoutes,
  BusinessOwnerRoutes,
  CalendarRoutes,
  PackageLessonRoutes,
  SingleLessonRoutes,
  ProfileRoutes,
  ErrorRoutes,
  MyContentRoutes,
];

export const AdminRoutes = () => {
  return useRoutes(adminRoutes);
};

export const AppRoutes = () => {
  return useRoutes(appRoutes);
};
