import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// profile option routing

const Profile = Loadable(lazy(() => import('views/admin/profile/Profile')));

// ==============================|| PROFILE ROUTING ||============================== //

const ProfileRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/business/profile',
      element: <Profile />,
    },
    {
      path: '/:userId/profile',
      element: <Profile />,
    },
  ],
};

export default ProfileRoutes;
