import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';

// SingleLesson option routing
const SingleLesson = Loadable(
  lazy(() => import('views/app/single-lesson/SingleLesson'))
);

// ==============================|| SINGLE LESSON ROUTING ||============================== //

const SingleLessonRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/app/single-lesson',
      element: <SingleLesson />,
    },
  ],
};

export default SingleLessonRoutes;
