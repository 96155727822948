import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';

// my-content option routing

const MyContent = Loadable(
  lazy(() => import('views/app/my-content/MyContent'))
);

// ==============================|| MY CONTENT ROUTING ||============================== //

const MyContentRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/my-content',
      element: <MyContent />,
    },
  ],
};

export default MyContentRoutes;
