import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';

// PackageLesson option routing
const PackageLesson = Loadable(
  lazy(() => import('views/app/package-lesson/PackageLesson'))
);

// ==============================|| PACKAGE LESSON ROUTING ||============================== //

const PackageLessonRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      // will be added PackageLesson route path
      path: '/app/package-lesson',
      // will be added PackageLesson component
      element: <PackageLesson />,
    },
  ],
};

export default PackageLessonRoutes;
