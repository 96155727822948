import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/common/Loadable';
import MainLayout from 'layout/MainLayout';

// AppDasboard option routing

const AppDashboard = Loadable(lazy(() => import('views/dashboard/App')));

// ==============================|| APP DASHBOARD ROUTING ||============================== //

const AppDashboardRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/app',
      element: <AppDashboard />,
    },
  ],
};

export default AppDashboardRoutes;
