import axios from 'axios';
import { getAuthHeader } from 'store/auth/helpers';

const token = getAuthHeader();

const isDev = false;

const sportiveApi = axios.create({
  baseURL: isDev
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_REMOTE_URL,

  headers: {
    'Content-type': 'application/json',
  },
});

// Add a request interceptor
sportiveApi.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return { ...config, headers: { ...token } };
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

sportiveApi.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return {
      ...response,
      data: response.data,
    };
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default sportiveApi;
