import {
  LOGIN_FAIL,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_START,
  REGISTER_SUCCESS,
  USER,
} from './constants';

import AuthService from '../../services/auth';

export const loginStart = (dispatch) => {
  dispatch({ type: LOGIN_START });
};

export const loginSuccess = (dispatch, response) => {
  const user = {
    ...response.data.user,
    accessToken: response.data.accessToken,
  };

  if (response.data.accessToken) {
    localStorage.setItem(USER, JSON.stringify(response.data));
    dispatch({ type: LOGIN_SUCCESS, payload: user });
  }
};

export const loginFail = (dispatch, error) => {
  dispatch({ type: LOGIN_FAIL, payload: error });
};

export const login = (email, password) => {
  return async (dispatch) => {
    loginStart(dispatch);
    try {
      await AuthService.login({ email, password }).then((response) => {
        loginSuccess(dispatch, response);
      });
    } catch (error) {
      loginFail(dispatch, error?.response?.data?.message);
    }
  };
};

export const registerStart = (dispatch) => {
  dispatch({ type: REGISTER_START });
};

export const registerSuccess = (dispatch, response) => {
  const user = {
    ...response.data.user,
    accessToken: response.data.accessToken,
  };

  if (response.data.accessToken) {
    localStorage.setItem(USER, JSON.stringify(response.data));
    dispatch({ type: REGISTER_SUCCESS, payload: user });
  }
};

export const registerFail = (dispatch, error) => {
  dispatch({ type: REGISTER_FAIL, payload: error });
};

export const register = ({
  email,
  password,
  firstName,
  lastName,
  phone,
  role,
  onSuccess,
}) => {
  return async (dispatch) => {
    registerStart(dispatch);
    try {
      const body = {
        email,
        password,
        role,
        firstName,
        lastName,
        phone,
      };

      await AuthService.register(body).then((response) => {
        registerSuccess(dispatch, response);
      });
      onSuccess?.();
    } catch (error) {
      registerFail(dispatch, error?.response?.data?.message);
    }
  };
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
